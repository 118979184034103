<template>
  <img
    src="./assets/logo.png"
    alt="Deviverize"
    class="logo"
    @click="
      () => {
        this.$router.push('/');
      }
    "
  />
  <div id="nav">
    <router-link to="/" v-if="menuList">Liste des devis</router-link>
    <a v-if="!menuList" @click="createDevis">Nouveau Devis</a>
  </div>
  <router-view />
</template>
<script>
export default {
  data() {
    return {
      menuList: false,
    };
  },
  watch: {
    $route() {
      if (
        window.location.href.substring(
          window.location.href.lastIndexOf("/") + 1
        ) === ""
      ) {
        this.menuList = false;
      } else {
        this.menuList = true;
      }
    },
  },
  methods: {
    getAValidDate() {
      let today = new Date();
      let newDate = new Date();
      newDate.setMonth(today.getMonth() + 3);
      let options = { year: "numeric", month: "long", day: "numeric" };
      let newDateFormatted = newDate.toLocaleDateString("fr-FR", options);
      return newDateFormatted;
    },
    async createDevis() {
      await fetch(process.env.VUE_APP_BACKEND_URL + "/getHighestNumDevisValue", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          const maxdevis = response;
          const numdevis = maxdevis + 1;
          const data = {};
          data.numdevis = {
            show: true,
            value: numdevis,
          };

          data.type = "";
          data.madeby = "remi";
          data.client = {
            show: true,
            name: "",
            society: "",
            adress: "",
            cp: "",
          };
          data.title = "Devis";
          data.subtitle = "";
          data.validityDate = this.getAValidDate();
          data.weaverizePresentation =
            "Weaverize est une société du nord de la France de développement d'applications. Weaverize a été fondée en 2017 et compte aujourd'hui 7 employés. Nous réalisons tout types d'applications, et nous avons des spécialisations dans les domaines de la vidéo et de la géolocalisation.";
          data.objectif =
            "L’objectif de cette prestation est la création d'une application permettant...";
          data.sectionsdoc = [];
          data.team = {
            show: true,
            raphael: true,
            robin: true,
            remi: true,
            gauthier: false,
            noemie: false,
            valentin: false,
            thibault: false,
          };
          data.deliveries = true;
          data.maintenance = true;
          data.showtariffinal = true;
          data.priceInputs = [];
          data.tjm = 450;
          data.rib = true;
          data.percentarrhes = 30;

          fetch(process.env.VUE_APP_BACKEND_URL + "/createdevis", {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({
              devisContent: data,
            }),
          })
            .then((res) => {
              return res.json();
            })
            .then((json) => {
              this.$router.push("/devis/" + json._id);
            })
            .catch((err) => {
              console.error(err);
              return "error";
            });
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    },
  },
};
</script>
<style>
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}

#nav {
  padding: 10px 0 20px;
  width: 50vw;
}

#nav a {
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  color: #2c3e50;
  text-transform: uppercase;
  background-color: rgb(70, 195, 154);
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 100px;
  border: solid 3px rgb(35, 130, 99);
  text-decoration: none;
}
.logo {
  height: 150px;
  margin-top: 1rem;
  cursor: pointer;
  margin-right: calc(69vw - 90px);
  margin-left: calc(20vw - 90px);
}
</style>
