import { createRouter, createWebHistory } from 'vue-router'
import Devis from "../views/Devis.vue";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import("../views/Home.vue"),
    },
    {
      path: '/devis/:id',
      name: 'Devis',
      component: Devis,
    },
    {
      path: '/newdevis',
      name: 'newDevis',
      component: Devis,
    }
  ]
})

export default router
