<script>
export default {
  data() {
    return {
      spinnershow: true,
      generationloading: false,
      generateafter: false,
      srcPdfBase: process.env.VUE_APP_BACKEND_URL + "/pdf",
      srcPdf: "",
      devisId: null,
      type: "",
      madeby: "",
      client: {
        show: true,
        name: "",
        society: "",
        adress: "",
        cp: "",
      },
      title: "Devis:",
      numdevis: {
        show: true,
        value: 0,
      },
      validityDate: "",
      subtitle: "",
      weaverizePresentation: "",
      objectif: "",
      sectionsdoc: [],
      team: {
        show: false,
        raphael: false,
        robin: false,
        remi: false,
				gauthier: false,
				noemie: false,
				valentin: false,
				thibault: false,
        
      },
      deliveries: false,
      maintenance: false,
      showtariffinal: false,
      priceInputs: [],
      tjm: 0,
      rib: false,
      percentarrhes: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.submitForm().then(() => {
      next();
    });
  },
  mounted: async function () {
    if (this.$route.params.id) {
      fetch(`${process.env.VUE_APP_BACKEND_URL + '/' + this.$route.params.id}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {

          this.devisId = response._id;
          this.type = response.content.type;
          this.madeby = response.content.madeby;
          this.client = response.content.client;
          this.title = response.content.title;
          this.numdevis = response.content.numdevis;
          this.subtitle = response.content.subtitle;
          this.validityDate = response.content.validityDate;
          this.weaverizePresentation = response.content.weaverizePresentation;
          this.objectif = response.content.objectif;
          this.team = response.content.team;
          this.deliveries = response.content.deliveries;
          this.maintenance = response.content.maintenance;
          this.showtariffinal = response.content.showtariffinal;
          this.priceInputs = response.content.priceInputs;
          this.sectionsdoc = response.content.sectionsdoc;
          this.tjm = response.content.tjm;
          this.rib = response.content.rib;
          this.percentarrhes = response.content.percentarrhes;
          
          this.generateDevis();
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  },
  methods: {
    getValidDate(e) {
      e.preventDefault();
      let today = new Date();
      let newDate = new Date();
      newDate.setMonth(today.getMonth() + 3);
      let options = { year: "numeric", month: "long", day: "numeric" };
      let newDateFormatted = newDate.toLocaleDateString("fr-FR", options);
      this.validityDate = newDateFormatted;
      blur();
    },
    blur() {
      if(this.generationloading) {
        this.generateafter = true;
      } else {
        this.generateDevis();
      }
    },

    generateDevis() {
      this.spinnershow = true;
      this.generationloading = true;
      const data = {};
      data.type = this.type;
      data.madeby = this.madeby;
      data.client = this.client;
      data.title = this.title;
      data.numdevis = this.numdevis;
      data.subtitle = this.subtitle;
      data.validityDate = this.validityDate;
      data.weaverizePresentation = this.weaverizePresentation;
      data.objectif = this.objectif;
      data.team = this.team;
      data.deliveries = this.deliveries;
      data.maintenance = this.maintenance;
      data.showtariffinal = this.showtariffinal;
      data.priceInputs = this.priceInputs;
      data.sectionsdoc = this.sectionsdoc;
      data.tjm = this.tjm;
      data.rib = this.rib;
      data.percentarrhes = this.percentarrhes;
      fetch(process.env.VUE_APP_BACKEND_URL + "/editdevis", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          devisId: this.devisId,
          editedContent: data,
        }),
      })
        .then(() => this.reloadIframe(this.devisId))
        .catch((err) => {
          console.error(err);
          return "error";
        });
    },
    reloadIframe(devisId) {
      document.getElementById("iframesuper").src =
        this.srcPdfBase + "/" + devisId;
      this.spinnershow = false;
      this.generationloading = false;
      if(this.generateafter) {
        this.generateafter = false;
        this.generateDevis();
      }
    },
    addInput() {
      this.priceInputs.push({
        name: "",
        quantity: 0,
      });
    },
    deleteInput(index) {
      this.priceInputs.splice(index, 1);
    },
    addSection() {
      this.sectionsdoc.push({
        title: "",
        sectiontext: "",
        subsections: [],
        tarif: [],
        inputtarif: this.generateRandomString()
      });
    },
    deleteSection(index) {
      this.sectionsdoc.splice(index, 1);
    },
	addSubSection(i) {
      this.sectionsdoc[i].subsections.push({
        title: "",
        sectiontext: "",
      });
    },
    deleteSubSection(i, index) {
      this.sectionsdoc[i].subsections.splice(index, 1);
    },
	addInputPrice(i) {
      this.sectionsdoc[i].tarif.push({
        name: "",
        quantity: "",
      });
    },
    deleteInputPrice(i, index) {
      this.sectionsdoc[i].tarif.splice(index, 1);
    },
    savePdf() {
      fetch(process.env.VUE_APP_BACKEND_URL + "/savedevis", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          devisId: this.devisId,
        }),
      })
        .then((res) => {
          return this.$router.push("/");
        })
        .catch((err) => {
          console.error(err);
          return "error";
        });
    },
    generateRandomString() {
      const possibleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let randomString = "";

      for (let i = 0; i < 10; i++) {
        randomString += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
      }

      return randomString;
    },
    prevent(e) {
      e.preventDefault();
      this.submitForm();
    },
    async submitForm() {
      const data = {};
      data.type = this.type;
      data.madeby = this.madeby;
      data.client = this.client;
      data.title = this.title;
      data.numdevis = this.numdevis;
      data.subtitle = this.subtitle;
      data.validityDate = this.validityDate;
      data.weaverizePresentation = this.weaverizePresentation;
      data.objectif = this.objectif;
      data.team = this.team;
      data.deliveries = this.deliveries;
      data.maintenance = this.maintenance;
      data.showtariffinal = this.showtariffinal;
      data.priceInputs = this.priceInputs;
      data.sectionsdoc = this.sectionsdoc;
      data.tjm = this.tjm;
      data.rib = this.rib;
      data.percentarrhes = this.percentarrhes;
      if (this.devisId) {
        fetch(process.env.VUE_APP_BACKEND_URL + "/updatedevis", {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            devisId: this.devisId,
            devisContent: data,
          }),
        })
          .then(() => {
            return this.$router.push("/");
          })
          .catch((err) => {
            console.error(err); 
            return "error";
          });
      } else {
        fetch(process.env.VUE_APP_BACKEND_URL + "/createdevis", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            devisId: this.devisId,
            devisContent: data,
          }),
        })
          .then(() => {
            return this.$router.push("/");
          })
          .catch((err) => {
            console.error(err);
            return "error";
          });
      }
    },
  },
};
</script>

<template>
  <div class="home">
    <form class="form">
      <h2>Introduction du document</h2>
      <label for="title"
        >&#9679; Titre du devis:
        <input
          @blur="blur"
          type="text"
          name="title"
          v-model="title"
          placeholder="Titre du devis"
        />
      </label>
      <fieldset>
        <legend>&#9679; Numero de téléphone de:</legend>

        <div>
          <input
            @blur="blur"
            type="radio"
            name="remi"
            value="remi"
            checked
            v-model="madeby"
          />
          <label for="remi">Remi</label>
        </div>

        <div>
          <input
            @blur="blur"
            type="radio"
            name="robin"
            value="robin"
            v-model="madeby"
          />
          <label for="robin">Robin</label>
        </div>
      </fieldset>
      <label for="numdevisshow"
        >&#9679; Afficher le numéro de Devis
        <input
          @blur="blur"
          type="checkbox"
          name="numdevisshow"
          v-model="numdevis.show"
        />
      </label>
      <label for="numdevis" v-if="numdevis.show" class="clientinputs2"
        >Devis numéro
        <input
          @blur="blur"
          type="number"
          min="0"
          name="numdevis"
          v-model="numdevis.value"
        />
      </label>
      <label for="client"
        >&#9679; Afficher une adresse client
        <input
          @blur="blur"
          type="checkbox"
          name="client"
          v-model="client.show"
        />
      </label>
      <div class="clientinputs" v-if="client.show">
        <label for="clientName"
          >Nom du client:
          <input
            @blur="blur"
            type="text"
            name="clientName"
            v-model="client.name"
          />
        </label>
        <label for="clientSociety"
          >Nom de la société du client:
          <input
            @blur="blur"
            type="text"
            name="clientSociety"
            v-model="client.society"
          />
        </label>
        <label for="clientAdress"
          >Adresse du client:
          <input
            @blur="blur"
            type="text"
            name="clientAdress"
            v-model="client.adress"
          />
        </label>
        <label for="clientCP"
          >Code Postal du client:
          <input @blur="blur" type="text" name="clientCP" v-model="client.cp" />
        </label>
      </div>
      <label for="subtitle" class="textarea">&#9679; Sous-titre du devis:</label>
      <textarea
        @blur="blur"
        name="subtitle"
        v-model="subtitle"
        placeholder="Sous-titre du devis"
      ></textarea>

      <div class="leftdiv">
        <label for="validityDate"
          >&#9679; Date de validité:
          <input
            @blur="blur"
            type="text"
            name="validityDate"
            v-model="validityDate"
            placeholder="1 janvier 2000"
          />
        </label>
        <button class="datebutton" @blur="blur" @click="getValidDate">
          Dans 3 mois
        </button>
      </div>
      <label for="weaverizePresentation" class="textarea"
        >&#9679; Présentation Weaverize:</label
      >
      <textarea
        @blur="blur"
        name="weaverizePresentation"
        v-model="weaverizePresentation"
        placeholder="Weaverize est une société du nord de la France de développement d'applications."
      ></textarea>

      <label for="objectif" class="textarea">&#9679; Objectifs:</label>
      <textarea
        @blur="blur"
        name="objectif"
        v-model="objectif"
        placeholder="L’objectif de cette prestation est la création d'une application permettant..."
      ></textarea>

      <h2>Corps du document</h2>

      <div>
        <p class="textaddinput">
          &#9679; Ajouter une section
          <span @click="addSection" class="addinput">+</span>
        </p>

        <ul>
          <li v-for="(input, index) in sectionsdoc" :key="index">
            <div class="champtarif">
              <label
                >Titre de la section {{ index + 1 }}
                <input type="text" v-model="input.title"
              /></label>
              <label for="sectiontext" class="textarea"
                >Texte d'intro de la section:</label
              >
              <textarea
                @blur="blur"
                name="sectiontext"
                v-model="input.sectiontext"
                placeholder="Texte d'introduction de la section"
              ></textarea>
              <div>
                <p class="textaddinput">
                  &#9679; Ajouter une sous-section
                  <span @click="addSubSection(index)" class="addinput">+</span>
                </p>
                <ul>
                  <li v-for="(aninput, anindex) in input.subsections" :key="anindex">
                    <div class="champtarif">
                      <label
                        >Titre de la sous-section {{ anindex + 1 }}
                        <input type="text" v-model="aninput.title"
                      /></label>
                      <label for="sectiontext" class="textarea"
                        >Texte de la sous-section:</label
                      >
                      <textarea
                        @blur="blur"
                        name="sectiontext"
                        v-model="aninput.sectiontext"
                        placeholder="Texte d'introduction de la section"
                      ></textarea>
                      <span @click="deleteSubSection(index, anindex)" class="deletechamp"
                        >x</span
                      >
                    </div>
                  </li>
                </ul>
              </div>

	  <div>
        <p class="textaddinput">
          &#9679; Ajouter un champ de Tarifications
          <span @click="addInputPrice(index)" class="addinput">+</span>
        </p>

        <ul>
          <li v-for="(inputprice, indexprice) in input.tarif" :key="indexprice">
            <div class="champtarif">
              <label
                >Nom du champ {{ indexprice + 1 }}
                <input type="text" v-model="inputprice.name"
              /></label>
              <label
                >Quantité de jour
                <input
                  class="lastinput"
                  type="number"
                  min="0"
                  v-model="inputprice.quantity"
              /></label>
              <span @click="deleteInputPrice(index, indexprice)" class="deletechamp">x</span>
            </div>
          </li>
        </ul>
      </div>

              <span @click="deleteSection(index)" class="deletechamp">x</span>
            </div>
          </li>
        </ul>
      </div>

      <h2>Conclusion du document</h2>

      <label for="team"
        >&#9679; Afficher une équipe
        <input @blur="blur" type="checkbox" name="team" v-model="team.show" />
      </label>
      <div class="clientinputs" v-if="team.show">
        <label for="remi"
          >Rémi est dans l'équipe
          <input @blur="blur" type="checkbox" name="remi" v-model="team.remi" />
        </label>
        <label for="robin"
          >Robin est dans l'équipe
          <input
            @blur="blur"
            type="checkbox"
            name="robin"
            v-model="team.robin"
          />
        </label>
        <label for="gauthier"
          >Gauthier est dans l'équipe
          <input
            @blur="blur"
            type="checkbox"
            name="gauthier"
            v-model="team.gauthier"
          />
        </label>
        <label for="valentin"
          >Valentin est dans l'équipe
          <input
            @blur="blur"
            type="checkbox"
            name="valentin"
            v-model="team.valentin"
          />
        </label>
        <label for="raphael"
          >Raphaël est dans l'équipe
          <input
            @blur="blur"
            type="checkbox"
            name="raphael"
            v-model="team.raphael"
          />
        </label>
        <label for="noemie"
          >Noémie est dans l'équipe
          <input
            @blur="blur"
            type="checkbox"
            name="noemie"
            v-model="team.noemie"
          />
        </label>
        <label for="thibault"
          >Thibault est dans l'équipe
          <input
            @blur="blur"
            type="checkbox"
            name="thibault"
            v-model="team.thibault"
          />
        </label>
      </div>

      <label for="deliveries"
        >&#9679; Livrables
        <input
          @blur="blur"
          type="checkbox"
          name="deliveries"
          v-model="deliveries"
        />
      </label>
      <label for="maintenance"
        >&#9679; Hébergement & Maintenance
        <input
          @blur="blur"
          type="checkbox"
          name="maintenance"
          v-model="maintenance"
        />
      </label>

      <label for="showtarif"
        >&#9679; Afficher un tableau de Tarification Final
        <input
          @blur="blur"
          type="checkbox"
          name="showtarif"
          v-model="showtariffinal"
        />
      </label>

      <div v-if="showtariffinal">
        <p class="textaddinput">
          &#9679; Ajouter un champ de Tarifications
          <span @click="addInput" class="addinput">+</span>
        </p>

        <ul>
          <li v-for="(input, index) in priceInputs" :key="index">
            <div class="champtarif">
              <label
                >Nom du champ {{ index + 1 }}
                <input type="text" v-model="input.name"
              /></label>
              <label
                >Quantité de jour
                <input
                  class="lastinput"
                  type="number"
                  min="0"
                  v-model="input.quantity"
              /></label>
              <span @click="deleteInput(index)" class="deletechamp">x</span>
            </div>
          </li>
        </ul>
      </div>

      <label for="tjm"
        >&#9679; Taux Journalier Moyen:
        <input type="number" min="0" name="tjm" v-model="tjm" />€
      </label>
      <label for="rib"
        >&#9679; Afficher le RIB
        <input @blur="blur" type="checkbox" name="rib" v-model="rib" />
      </label>
      <label for="percentarrhes"
        >&#9679; Pourcentage arrhes:
        <input type="number" min="0" max="100" name="percentarrhes" v-model="percentarrhes" />%
      </label>
    </form>
  </div>
  <div class="buttongenerate">
    <button @click="prevent" :class="[spinnershow ? 'blocked' : '']">
      Sauvegarder le devis
    </button>
    <button @click="savePdf" :class="[spinnershow ? 'blocked' : '']">
      Finaliser le devis
    </button>
  </div>
  <div class="pdfviewer">
    <div v-if="spinnershow" class="lds-dual-ring"></div>
    <iframe id="iframesuper" :src="srcPdf"></iframe>
  </div>
  <div @click="blur" class="refreshbutton">&#128472;</div>
</template>

<style>
.form h2 {
  text-transform: uppercase;
  margin-bottom: 3rem;
}
h2::before,
h2::after {
  display: block;
  content: "";
  width: 100%;
  border-bottom: solid 2px black;
}
.leftdiv {
  text-align: left;
}
fieldset {
  display: flex;
  height: 60px;
  padding: 0 0 1rem 0;
  justify-content: space-evenly;
}
fieldset input[type='radio']:checked:after {
        width: 13px;
        height: 13px;
        border-radius: 15px;
        position: relative;
        background-color: #11a37b;
        content: '';
        display: inline-block;
        visibility: visible;
    }
legend {
  text-align: left;
  font-weight: bold;
}
input[type='checkbox']{
   accent-color:#11a37b;
   width:20px;
   height:20px;
}
.form .datebutton {
  font-size: 1rem;
}
.refreshbutton {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  width: 2rem;
  height: 2rem;
  font-size: 1.4rem;
  border: solid 3px;
  background-color: rgb(42, 169, 127);
  border-color: rgb(21, 100, 67);
  border-radius: 10px;
}
.refreshbutton:hover {
  cursor: pointer;
  background-color: rgb(36, 112, 87);
}
.buttongenerate {
  width: 50vw;
  margin-right: 50vw;
}
.buttongenerate button {
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;
  border-radius: 10px;
  color: white;
  background-color: rgb(50, 202, 151);
  border-color: rgb(21, 100, 67);
}
.buttongenerate :first-child {
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;
  border-radius: 10px;
  color: white;
  background-color: rgb(111, 179, 184);
  border-color: rgb(21, 100, 67);
}
.buttongenerate button:hover {
  cursor: pointer;
  background-color: rgb(36, 112, 87);
}
.buttongenerate .blocked {
  pointer-events: none;
  cursor: initial;
  background-color: #bfbfbf;
  border-color: #868686;
}
.pdfviewer {
  position: absolute;
  right: 0;
  top: 0;
  width: 50vw;
  height: 100vh;
  background-color: rgb(106, 228, 177);
}
.pdfviewer iframe {
  margin: 2% auto;
  width: 75%;
  height: 95%;
}
.home {
  overflow: auto;
  width: 50vw;
  max-height: calc(100vh - 300px);
  background-color: rgba(106, 228, 177, 0.178);
}
.form {
  display: flex;
  margin: 1rem auto;
  flex-direction: column;
  max-width: 500px;
}
.form * {
  margin-bottom: 1rem;
}
.form label {
  text-align: left;
  font-weight: bold;
}
.form button {
  max-width: 200px;
  margin: 0 auto;
  font-size: 1.5rem;
}
.clientinputs {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.clientinputs2 {
  margin-left: 2rem;
}
textarea {
  max-width: 500px;
  min-height: 100px;
}
.textaddinput {
  text-align: left;
  font-weight: bold;
}
.addinput {
  background-color: #5ec2a1be;
  padding: 0.4rem 0.7rem;
  font-weight: bold;
  font-size: 25px;
  border-radius: 5px;
  cursor: pointer;
}
.champtarif {
  border-radius: 10px;
  position: relative;
  display: flex;
  margin: 0 auto;
  padding: 0.8rem;
  flex-direction: column;
  background-color: #5ec2a13f;
}
ul {
  list-style-type: none;
  margin-block: 0;
  padding-inline-start: 0;
}
.deletechamp {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  font-size: 23px;
  background: rgb(180, 48, 48);
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #ffffff;
  cursor: pointer;
}
.lastinput {
  margin-bottom: 0;
}
.lds-dual-ring {
  position: absolute;
  display: inline-block;
  top: calc(50% - 104px);
  left: calc(50% - 72px);
  width: 152px;
  height: 152px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 12px solid rgb(60, 187, 160);
  border-color: rgb(60, 187, 160) transparent rgb(60, 187, 160) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
